import { RecoilRoot } from 'recoil';
import { PortalManager, ChakraProvider } from '@chakra-ui/react';
import { theme } from '../theme';

const Root = ({ children }: { children: React.ReactElement }) => (
  <RecoilRoot>
    <ChakraProvider theme={theme}>
      <PortalManager>{children}</PortalManager>
    </ChakraProvider>
  </RecoilRoot>
);

export default Root;
