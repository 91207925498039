import { extendTheme, Theme } from '@chakra-ui/react';

/* eslint-disable */
// @TODO: Typing & linting.

export const theme = extendTheme({
  styles: {
    global: () => ({
      '::selection': {
        background: 'green.200',
      },
      // '::-webkit-scrollbar': {
      //   width: '10px',
      // },
      // '::-webkit-scrollbar-track': {
      //   background: 'gray.100',
      // },
      // '::-webkit-scrollbar-thumb': {
      //   background: 'green.400',
      // },
    }),
  },
  components: {
    Heading: {
      sizes: {
        '4xl': {
          mb: 6,
        },
        '3xl': {
          mb: 6,
        },
        '2xl': {
          mb: 6,
        },
        xl: {
          mb: 8,
        },
        lg: {
          mb: 7,
        },
        md: { fontSize: ['xl', null, '2xl'], mb: 6 },
        sm: { fontSize: ['md', null, 'lg'], mb: 3 },
        xs: { fontSize: ['sm', null, 'md'], mb: 6 },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'none',
        lineHeight: 1,
        minW: 'none',
      },
      sizes: {
        lg: {
          h: 14,
        },
        md: {
          h: 12,
        },
        sm: {
          h: 10,
        },
        xs: {
          h: 8,
        },
      },
    },
    Link: {
      baseStyle: (props: Record<string, any>) => {
        const { colorScheme: c = 'green' } = props;

        if (c.includes('white')) {
          return {
            color: c,
            _hover: {
              color: `green.500`,
              textDecoration: 'none',
            },
          };
        }

        return {
          color: `${c}.600`,
          _hover: {
            color: `${c}.500`,
            textDecoration: 'none',
          },
        };
      },
      variants: {
        unstyled: {
          color: 'inherit',
          textDecoration: 'none',
          _hover: {
            color: 'auto',
            textDecoration: 'none',
          },
        },
      },
    },
    Container: {
      baseStyle: {
        width: '100%',
        px: 8,
        maxW: '7xl',
      },
    },
  },
  fonts: {
    body: '"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    alternative: `"Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`,
    heading: `"Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`,
    mono: `SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
  },
  colors: {
    green: {
      100: '#E7F9F2',
      200: '#c8f8e3',
      300: '#B8E2C9',
      400: '#99f3bd',
      500: '#28df99',
      600: '#13c183',
    },
  },
}) as Theme;
